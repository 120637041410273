.sidebaradmin {
    width: 268px;
    padding: 24px 16px;
    border-right: 1px solid #E7E7E7;
    background: #FFF;
    display: flex;
    flex-direction: column;
    position: relative;

    .toggle-button {
        display: none;
    }

    .toggle-button {
        // position: absolute;
        // background: #0068ff;
        // border: 1px solid #0068ff;
        // border-radius: 50%;
        // right: -15px;
        // top: 14px;
        // display: block;
        // width: 35px;
        // height: 35px;
        // svg{
        //     width: 15px;
        //     height: 15px;
        //     path{
        //         fill: #FFF;
        //     }
        // }
    }

    &.open {
        .toggle-button {
            svg.close {
                display: none;
            }
        }

        h2 {
            svg.close {
                display: none;
            }
        }
    }

    &.closed {
        width: 67px;

        .toggle-button {
            svg.open {
                display: none;
            }
        }

        h2 {
            svg.open {
                display: none;
            }
        }

        ul a {
            font-size: 0;
            padding: 10px 5px;
        }
    }

    @media (max-width:991px) {
        &.open {
            position: absolute;
            left: 0;
            z-index: 9;
            height: 100%;
            padding: 24px 6px;
        }
    }

    h2 {
        margin-bottom: 32px;

        svg.close {
            position: relative;
            left: -8px;
        }

        @media (max-width:991px) {
            margin: 0px 0 10px;

            svg.close {
                width: 40px;
                height: 40px;
                display: block;
                left: -3px;
            }
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: large;
        flex-direction: column;
        gap: 4px;
        display: flex;
        width: 100%;
        height: 100%;

        a,
        button {
            text-decoration: none;
            display: flex;
            padding: 12px;
            border-radius: 8px;
            gap: 8px;
            color: #9D9EA0;
            align-items: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: 24px;

            svg {
                path {
                    stroke: #9D9EA0;
                }
            }

            &:hover,
            &.active {
                background-color: #E5F0FF;
                color: #0068FF;

                svg {
                    path {
                        stroke: #0068FF;
                    }
                }
            }
        }

        li:last-child {
            margin-top: auto;
        }
    }
}


@keyframes slide-right {
    from {
      margin-left: -100%;
    }
    to {
      margin-left: 0%;
    }
  }

  @keyframes slide-left {
    from {
      margin-left: 100%;
    }
    to {
      margin-left: 0%;
    }
  }