.headeradmin {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 32px;
    border-bottom: 1px solid #E7E7E7;
    @media (max-width:767px) {
        padding: 14px 20px;
    }
    .name {
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 32px;
        color: #0068FF;
        @media (max-width:767px) {
            font-size: 16px;
            line-height: 22px;
        }
    }
    .head_right{
        > ul {
            display: flex;
            list-style: none;
            align-items: center;
            gap: 4px;
            margin: 0;
            padding: 0;
            img {
                width: 24px;
                height: 24px;
                border-radius: 50%;
                object-fit: contain;
            }
            a.notification {
                width: 32px;
                height: 32px;
                border: 1px solid #E7E7E7;
                display: flex;
                align-items: center;
                justify-content: center;
                position: relative;
                border-radius: 64px;
            }
            button {
                background: #fff;
                display: flex;
                gap: 4px;
                border: 1px solid #E7E7E7;
                border-radius: 64px;
                color: #0A0D12;
                align-items: center;
                padding: 4px;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                font-family: "Poppins", sans-serif;
                &::after {
                    background: url(../../assets/imgs/arr_down.svg) transparent;
                    padding: 10px;
                    background-size: 20px;
                    border: 0;
                    margin: 0;
                }
                @media (max-width:767px) {
                    font-size: 0;
                    gap: 0;
                }
            }
        }

    } 
}

