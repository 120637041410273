@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Urbanist:ital,wght@0,100..900;1,100..900&display=swap');

body , *{
  font-family: "Urbanist", sans-serif;
}

.mainadminpanel {
  display: flex;
  flex-direction: row;
  height: 100vh;
}
.contentbar {
  flex: 1;
  background:#fff;
  height: 100%;
    overflow-y: auto;
}


