.innercontent {
    padding: 32px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    @media (max-width:767px) {
        padding: 20px;
    }

    .heading_sec {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media (max-width:767px) {
            flex-direction: column;
            align-items: flex-start;
            gap: 8px;
        }

        h4 {
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 30px;
            color: #0A0D12;
            margin: 0;
            @media (max-width:767px) {
                font-size: 16px;
                line-height: 22px;
            }
        }

        .rightheadinner {
            display: flex;
            gap: 8px;

            .searchform {
                position: relative;

                input {
                    width: 250px;
                    padding: 8px 8px 8px 42px;
                    border-radius: 8px;
                    border: 1px solid #E7E7E7;
                    @media (max-width:767px) {
                        width: 100%;
                    }

                    &::placeholder {
                        color: #9D9EA0;
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 20px;
                    }
                }

                button {
                    position: absolute;
                    left: 0;
                    background: transparent;
                    border: 0;
                    top: 0;
                    padding: 9px 10px;
                }
            }

            .filter {
                a {
                    color: #1B1B1B;
                    text-decoration: none;
                    font-weight: 500;
                    padding: 8px 12px;
                    border-radius: 64px;
                    border: 1px solid #E7E7E7;
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    font-family: "Poppins", sans-serif;
                    font-size: 12px;
                }
            }
        }
    }
    .datadiv {
        overflow: auto;
        border: 1px solid #e7e7e7;
        border-radius: 15px;
        table {  
            margin: 0; 
            width:100%;             
            th,
            td {
                background: #E7E7E7;
                padding: 12px 24px;
                border-bottom: 1px solid #E7E7E7;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 18px;
                color: #0A0D12;
                opacity: .5;
                vertical-align: middle;
            }
            th{
                &:first-child {
                border-radius: 15px 0 0 0 !important;
              }
              &:last-child {
                border-radius: 0 15px 0 0 !important;
              }
            }    
            td {
                background: #fff;
                opacity: 1;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px; 
                // border-left: 1px solid var(--Neutral-30, #E7E7E7);
                .num {
                    color: #0A0D12;
                    text-decoration: unset;
                }
                .edit{
                    display: flex;
                    border-radius: 64px;
                    border: 1px solid #E7E7E7;
                    display: flex;
                    padding: 8px 12px;
                    justify-content: center;
                    align-items: center;
                    gap: 6px;
                    text-decoration: unset;
                    width: 48px;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 20px;
                    color: #0068FF;
                }
                &:first-child {
                    border-radius: 0 0 0 15px !important;
                }
                &:last-child {
                    border-radius: 0 0 15px 0 !important;
                }
            }
        }
        .custonpagi {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 16px 0;
            .pagination {
                margin: 0;
                display: flex;
                text-align: center;
                gap: 8px;
                align-items: center;
                @media (max-width:767px) {
                    gap: 4px;
                }
                li{
                    &.next{
                        a.page-link {
                            width: 100px;
                            padding: 10px 16px 10px 14px;
                            border-radius: var(--Radius-Radius-card, 8px);
                            border: 1px solid var(--Neutral-30, #E7E7E7);
                            @media (max-width:767px) {
                                width: auto;
                                padding: 4px;
                                font-size: 12px;
                            }
                        }
                    }
                    &.active , &:hover{
                        a.page-link {
                            border-radius: 4px;
                            background: #E5F0FF;
                            color: #0068FF;
                        }
                    }
                    a.page-link {
                        font-size: 14px;
                        font-style: normal;
                        line-height: 20px;
                        color: #0A0D12;
                        width: 36px;
                        padding: 4px 6px;
                        border: 0;
                        cursor: pointer;
                        @media (max-width:767px) {
                            width: 22px;
                            padding: 2px 6px;
                        }
                        
                    }
                } 
                
                
            }
        }
    }
}


